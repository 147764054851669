// @flow
import React from 'react'
import cn from 'classnames'
import queryString from 'query-string'

import withTheme from 'hoc/withTheme'

import { fetchLead, patchLead } from 'Actions'
import { Store } from 'Store'

import styles from './styles.module.scss'

import themes, { type Theme } from './themes/__supportedThemes'

function Landing (props: { history: any, location: any, match: any, theme: Theme }) {
    const { dispatch } = React.useContext(Store)
    const [formState, setFormState] = React.useState(null)

    const handleSubmit = React.useCallback(async e => {
        e.preventDefault()
        setFormState('loading')

        try {
            let code = e.target.elements['code'].value
            await fetchLead(dispatch, code)
            await patchLead(dispatch, code, {}, true)
        } catch (err) {
            console.error(err)
            setFormState(null)
            return
        }

        // const company = 'Home Town Ford'
        // const response = await fetch(`https://playbook.shiftmarketing.io/shift-marketing-playbook.pdf?clientName=${company}`, {
        //     mode: 'cors'
        // })

        const response = await fetch('/shift-playbook.pdf')
        if (!response.ok) {
            console.error(await response.text())
            alert('Unable to download playbook.')
            return
        }

        const blob = await response.blob()

        const a = document.createElement('a')
        a.href = window.URL.createObjectURL(blob)
        a.download = 'Shift Marketing Playbook.pdf'
        document.body.appendChild(a) // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click()
        a.remove() // afterwards we remove the element again

        setFormState('submitted')
    }, [ dispatch ])

    return (
        <div className={styles.container}>
            <aside className={styles.leftSide}>
                <a className={styles.logoContainer} href='https://shiftmarketing.io/' target='_blank' rel="noopener noreferrer">
                    <div className={styles.logo} />
                </a>

                <div className={cn(styles.img, styles.img1)} />
                <div className={cn(styles.img, styles.img2)} />
                <div className={cn(styles.img, styles.img3)} />
            </aside>

            <div className={styles.rightSide}>

                <div className={styles.logoMobile}>
                    <a className={styles.logoContainer} href='https://shiftmarketing.io/' target='_blank' rel="noopener noreferrer">
                        <div className={styles.logo}/>
                    </a>
                </div>

                <h1 className={styles.title}>
                    Download your fundraising playbook today <strong>(it's free!).</strong>
                </h1>

                <form className={styles.form} onSubmit={handleSubmit}>
                    <input
                        name='code'
                        type="text"
                        placeholder="Enter your code"
                        defaultValue={queryString.parse(window.location.hash)['code']}
                    />
                    <button disabled={formState !== null}>
                        {formState === 'loading' ? 'Downloading...' : 'Download'}
                        <div className={styles.icon}/>
                    </button>
                </form>

                <div className={styles.information}>
                    <strong>What's inside:</strong>
                    <p>The pandemic and online giving.</p>
                    <p>The importance of flexible calling programs.</p>
                    <p>What we can learn going into 2021?</p>
                    <p>How Shift Marketing can help.</p>
                </div>

                <div className={styles.imgContainerMobile}>
                    <div className={cn(styles.img, styles.img1)} />
                    <div className={cn(styles.img, styles.img2)} />
                    <div className={cn(styles.img, styles.img3)} />
                </div>

                <footer className={styles.footer}>
                    <span>
                        Shift Marketing Studio Inc. © 2021, All rights reserved.
                    </span>
                    <span>
                        Canada: PO Box 66031 Town Centre, Pickering, ON L1V6P7   |   203 - 1737 Union Street, Schenectady, NY 12309
                    </span>
                </footer>
            </div>
        </div>
    )
}

export default withTheme(themes)(Landing)
