// @flow

import React, { Suspense } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import { Store } from 'Store'
import { fetchCampaign } from 'Actions'

import CampaignNotFound from 'components/CampaignNotFound'

import Landing from 'pages/Landing'

const IS_CAMPAIGN_URL = /^\/.+$/.test(window.location.pathname)

export const AppRoute = ({ component: Component, layout: Layout = MainLayout, ...rest }) => (
    <Route {...rest} render={props => (
        <Layout>
            <Component {...props} />
        </Layout>
    )}/>
)

const MainLayout = (props) => (<>{props.children}</>)

export default function App () {
    const { state, dispatch } = React.useContext(Store)

    React.useEffect(() => {
        if (IS_CAMPAIGN_URL && state.campaign === null && state.errors.campaignNotFound !== true) {
            fetchCampaign(dispatch)
        }

        if (state.campaign) {
            document.title = `${state.campaign.client.name}`
        }
    })

    React.useEffect(() => {
        if (IS_CAMPAIGN_URL && state.campaign === null) return
        setTimeout(() => {
            document.body.classList.remove('loading')
        }, 500)
    }, [state.campaign])

    if (state.errors.campaignNotFound) {
        return <CampaignNotFound />
    }

    if (IS_CAMPAIGN_URL && (state.campaign === null)) {
        return <h1>Loading</h1>
    }

    return (
        <Suspense fallback={<div>loading...</div>}>
            <BrowserRouter>
                <Switch>
                    <AppRoute exact path="/:url" component={Landing}/>
                </Switch>
            </BrowserRouter>
        </Suspense>
    )
}
